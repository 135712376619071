import {ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import OpenAI from "openai";
import {useState} from "react";

const DishItem = ({dish}) => {

    const openai = new OpenAI({
        apiKey: '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',
        dangerouslyAllowBrowser: true,
    });

    const [image, setImage] = useState('');


    const createItem = async (item) => {

        setImage('https://dummyimage.com/320x200/ffffff/000000&text=Laddar...+Ett+%C3%B6gonblick...');

        const response2 = await openai.images.generate({
            model: "dall-e-3",
            prompt: `
            Create a photo of a meal on a white plate. Show nothing else, just the plate.
            On the plate, show this meal: 
            ${item.title}, with this additional info: ${dish.text}
            `,
            n: 1,
            size: "1024x1024"
        });
        let result = response2.data;
        if (result.length > 0 && result[0].url) {
            setImage(result[0].url)
        } else {
            setImage(null);
        }
        return;

    };

    return <>
        <ListItem>


            <ListItemText
                onClick={() => createItem(dish)}
                sx={{maxWidth: '90%'}}
                primary={dish.title}
                secondary={dish.text}
            />

            <ListItemSecondaryAction>
                <Typography variant="body1" color="textSecondary">
                    {dish.price}
                </Typography>
            </ListItemSecondaryAction>


        </ListItem>
        {image &&
            <div style={{ width: '100%', marginTop: '8px' }}>
                <img
                    alt={dish.title}
                    src={image}
                    style={{ width: '100%', height: 'auto' }}
                />
            </div>
        }
    </>
};

export default DishItem
