import {forwardRef, useState} from "react";
import {Button, Card, CardContent, CardHeader, List} from "@mui/material";
import DishItem from "../DishItem/DishItem";
import {ContentCopy, VisibilityOff} from "@mui/icons-material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Restaurant({restaurant, onClick, searchTerm}) {

    const [open, setOpen] = useState(false);

    const handleCopy = (r) => {
        navigator.clipboard.writeText(generateText(r))

        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    function generateText(restaurant) {
        let str = [restaurant.name.toUpperCase(), ''];
        restaurant.dishes.forEach(dish => {
            if (dish.text !== '') {
                str.push("*" + dish.title + "* " + dish.text)
            } else {
                str.push(dish.title)
            }
            str.push('')
        })
        str = str.join('\n')
        return str
    }

    return <>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                Texten kopierades!
            </Alert>
        </Snackbar>
        <Card sx={{mt: "20px"}} elevation={5}>
            <CardHeader title={restaurant.name}/>
            <Button color="primary" onClick={onClick} sx={{mr: 9}}>
                <VisibilityOff sx={{mr: 1}}/>Dölj
            </Button>
            <Button color="primary" onClick={() => handleCopy(restaurant)}>
                <ContentCopy sx={{mr: 1}}/>Kopiera
            </Button>
            <CardContent>
                <List>
                    {
                        restaurant.dishes.filter(dish => {
                            if (restaurant.name.toUpperCase().includes(searchTerm.toUpperCase()))
                                return true;

                            return (
                                dish?.title?.toUpperCase().includes(searchTerm?.toUpperCase())
                                ||
                                dish?.text?.toUpperCase().includes(searchTerm?.toUpperCase())
                            );
                        }).map((dish, dishIndex) => (
                            <DishItem key={dishIndex} dish={dish}/>
                        ))
                    }
                </List>
            </CardContent>
        </Card>
    </>
}

export default Restaurant;
